/* Ellipse */
.lds-ellipse {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 40px;
}

.lds-ellipse div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--text);
  margin-top: -3.5px;
  top: 50%;
  left: 0;
  opacity: 0;
}

.lds-ellipse div:nth-child(1):after {
  animation: lds-ellipse 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: 0s;
}

.lds-ellipse div:nth-child(2):after {
  animation: lds-ellipse 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: 0.2s;
}

.lds-ellipse div:nth-child(3):after {
  animation: lds-ellipse 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: 0.5s;
}

.lds-ellipse div:nth-child(4):after {
  animation: lds-ellipse 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: 0.6s;
}

@keyframes lds-ellipse {
  0%,
  100% {
    opacity: 0;
    transform: translateX(0) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translateX(40px) scale(0.5);
  }
}
