:root {
  box-sizing: border-box;
  --nav-background: rgb(230, 230, 230);
  --nav-text: rgb(136, 136, 136);
  --nav-highlight: rgb(195, 226, 255);
  --font-color: rgb(69, 69, 69);
  --icon-color: rgb(252, 214, 99);
  --highlight: rgb(195, 226, 255);
  --error: rgb(234, 57, 57);
  --margin: 2rem;
  --alert-error-text: rgb(234, 57, 57);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
body {
  margin: 0;
}
h1 {
  margin: 0;
}
h2 {
  margin: 0;
}

h5 {
  margin: 0;
}
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-color);
}
p {
  margin: 0;
  padding: 0.5rem 0;
}
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

select {
  box-sizing: content-box;
}
/* Navigation */
header {
  background-color: var(--nav-background);
}
.logo {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.nav li {
  word-wrap: break-word;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.nav li:hover {
  background-color: var(--nav-highlight);
}
.nav a {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
}
.nav a:hover {
  background-color: rbg(240, 240, 240);
}
.logo:hover {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
  background-color: rbg(240, 240, 240);
}

.drop-down {
  position: relative;
  display: inline-block;
}
.drop-down-item {
  background-color: var(--nav-highlight);
  top: auto;
  position: absolute;
  display: none;
}
.drop-down > a:after {
  content: "  🡣";
}
li .drop-down:hover .drop-down-item {
  overflow: hidden;
  width: 100%;
  display: block;
}
/* Alerts */
.alert-error {
  color: var(--alert-error-text);
}

/* Forms */
.form-container {
  min-width: calc(100% - 6vw);
}
.form-label {
  color: var(--font-color);
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0.33rem 0;
}
.form-label-small {
  display: block;

  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;

  color: #38464e;
}
.form-input {
  display: block;
  flex-grow: 1;
  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7eef2;
  border-radius: 5px;
}

.form-control {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7eef2;
  border-radius: 5px;
}
.form-textarea {
  width: 100%;
  display: block;
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 1rem 1rem;
  min-height: 5em;
  outline: none;
}

/* Shapes */
.column {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  margin: 1rem 0;
  border-radius: 5px;
  display: flex;
}
.card {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.bar {
  width: 100%;
  display: flex;
}
.pair {
  display: flex;
  align-items: center;
}

/* Spacing */
.heading {
  margin: 1rem 0;
}
.soft {
  padding: 0.5rem 0.5rem;
}
.sidebar {
  margin: 1rem 1rem;
}
.list {
  margin: 0.5rem 0;
}
.item {
  margin: 0 0.5rem;
}
.square {
  margin: 0.5rem;
}

/* Position */
.left {
  justify-content: left;
}
.right {
  justify-content: right;
}
.center {
  justify-content: center;
}
.split {
  justify-content: space-between;
}

/* Column Position */
.c-left {
  align-items: flex-start;
}
.c-right {
  align-items: end;
}

/* Size */
.full {
  width: 100vw;
}
.almostfull {
  width: 90vw;
}
.thirds {
  width: 66vw;
}
.onethird {
  width: 33vw;
}
.half {
  width: 50vw;
}
.min {
  min-width: 60vw;
}

/* Buttons */
.btn-default {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  font-weight: 600;
  border-radius: 3px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
}

.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.focus:active,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  color: #38464e;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-job {
  display: flex;
  margin-bottom: 0.5rem;
  padding: 2rem;
  font-size: 14pt;
  line-height: 1.428571429;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  font-weight: 600;
  border-radius: 3px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
}

.btn-default:hover {
  color: #38464e;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.btn-factory {
  width: 150px;
  height: 150px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.btn-process {
  background: radial-gradient(circle, white, var(--highlight));
}

.btn-home {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
}
.btn-home {
  background-color: rbg(240, 240, 240);
}

.btn-loginout {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
}

.btn-pause {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
  height: 30vh;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 4rem var(--margin);
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn-end {
  display: block;
  padding: 1rem 1rem;
  line-height: normal;
  text-decoration: none;
  color: var(--nav-text);
  height: 30vh;
  justify-content: center;
  align-items: center;
  margin: 1rem var(--margin) 4rem 0;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.icon {
  padding: 0.5rem 0.5rem;
}
.icon:hover {
  cursor: pointer;
}

.drag-icon {
  padding: 0.5rem 0.5rem;
}

.drag-icon:hover {
  cursor: grab;
}

/* Timer */

.sw-button {
  display: flex;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  border: none;
}

.sw-timer {
  background-color: transparent;
  font-size: 72pt;
}

.sw-barcode {
  position: absolute;
  top: -100px;
}

.sw-numbers-green {
  color: #304316;
}

.sw-msg {
  height: 70px;
}
.sw-time {
  display: flex;
  /* width: calc(100% - var(--margin)); */
  height: 30vh;
  justify-content: center;
  align-items: center;
  margin: 1rem var(--margin) 4rem var(--margin);
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sw-face {
  border-radius: 15px;
  height: inherit;
  background-color: transparent;
}

.sw-animate {
  background: linear-gradient(30deg, #fff, #8cc63f);
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 300% 300%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.sw-animate-success {
  animation: bounce 0.5s ease;
}
@keyframes bounce {
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0, -5px);
  }
}

.sw-animate-error {
  animation: shake 0.5s ease;
}
@keyframes shake {
  0% {
    background-color: #c63f3f;
  }
  25% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(-10px, -10px);
  }
  75% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(-5px, -5px);
    background-color: #fff;
  }
}

.sw-flash {
  position: absolute;
  width: calc(100% - var(--margin));
  height: inherit;
  background-color: white;
  z-index: -1;
}

.sw-scan {
  animation: flash 0.75s ease-out;
  animation-fill-mode: backwards;
}
@keyframes flash {
  0% {
    background-color: #8cc63f;
    opacity: 1;
  }

  100% {
    background-color: white;
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
}

.sw-error {
  animation: error 0.75s ease-out;
  animation-fill-mode: backwards;
}
@keyframes error {
  0% {
    background-color: #c63f3f;
    opacity: 1;
  }

  100% {
    background-color: white;
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
}

.emp-scan {
  animation: emp-flash 0.75s ease-out;
  animation-fill-mode: backwards;
}
@keyframes emp-flash {
  0% {
    background-color: rgb(95, 164, 255);
    opacity: 1;
  }

  100% {
    background-color: white;
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
}

/* Spinner */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--border);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
